@import "./var.scss";
@import "./fonts.scss";
@import "./mixin.scss";

a {
	text-decoration: none;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.WorkSans {
	font-family: 'Work Sans Regular' !important;
}

.WorkSans-medium {
	font-family: 'Work Sans';
	font-weight: 500;
}

.roboto {
	font-family: 'Roboto' !important;
}

.color-white {
	color: white;
}

.color-black {
	color: black;
}

.color-naranja {
	color: $naranja;
}

.color-ladrillo {
	color: $ladrillo;
}

.color-ladrillo-oscuro {
	color: $ladrillo-oscuro;
}

.color-ocre {
	color: $ocre;
}

.color-gris {
	color: $gris;
}

.color-gris-claro {
	color: $gris-claro;
}

.color-gris-6 {
	color: $gris_6;
}

.color-gris_8 {
	color: $gris_8;
}

.bg-gris_8 {
	background-color: $gris_8;
}

.bg-gris-6 {
	background-color: $gris_6;
}

.color-gris_7 {
	color: $gris_7;
}

.bg-gris_7 {
	background-color: $gris_7;
}

.bg-naranja {
	background-color: $naranja;
}

.bg-gris_4 {
	background-color: $gris_4;
}

.bg-ladrillo {
	background-color: $ladrillo;
}

.bg-ladrillo-oscuro {
	background-color: $ladrillo-oscuro;
}

.bg-ocre {
	background-color: $ocre;
}

.bg-gris {
	background-color: $gris;
}

.bg-gris-claro {
	background-color: $gris-claro;
}

.bg-white {
	background-color: white;
}

.fwNormal {
	font-weight: normal;
}

.fw400 {
	font-weight: 400 !important;
}

.fw500 {
	font-weight: 500 !important;
}

.fw600 {
	font-weight: 600 !important;
}

.fw700 {
	font-weight: 700 !important;
}

.lh1 {
	line-height: 1.4vw;
}

.lh {
	line-height: 1.2;
}

.lh-normal {
	line-height: 1;
}

.lh_100 {
	line-height: 100%;
}

.fz1 {
	font-size: 0.9vw !important;
}

:focus {
	outline: none;
}

button:focus {
	outline: none;
}

.swiper-row {
	.swiper-grid-column {
		.swiper-wrapper {
			flex-wrap: wrap;
			flex-direction: row;
		}
	}
}

.slider_fecha {
	position: relative;
	height: 5vw;

	.swiper-wrapper {
		height: 5vw;
	}

	.swiper-slide {
		display: flex !important;
		align-items: center !important;
		justify-content: center;
	}


}

.modalGalaria {
	background-color: white;
	z-index: 100004;

	.modal-content {
		border: 0;
	}

	.modal-dialog {
		max-width: 100%;
		width: 100%;
		height: 100%;
		margin: 0;
	}

	.modal-header {
		border-bottom: none;
	}
}

.modal-pagos {
	z-index: 100005;

	.modal-dialog {
		width: auto;
	}
}

.modal-descargaSimu {
	height: 100%;
	max-height: 100vh;
	overflow-y: auto;

	.modal-dialog {
		width: 30%;
	}
}

.custom-modalNotifica {
	.modal-dialog {
		max-width: 90%;
		max-height: 90%;
	}
}

.custom-modalNotifica {
	.modal-content {
		width: 60vw;
		height: 100%;
		border-radius: 8px;
	}
}




// personalizar los radios boton 
.check-login {
	.form-check-input[type=checkbox] {
		border-radius: 50%;
		outline: none;
	}
}

// estilos de la modal
.modalSmall {
	width: 25% !important;

	.modalheader {
		display: flex;
		justify-content: flex-end;
		cursor: pointer;
		color: $gris_2;
		font-size: 1.6vw;
	}

	.modal-content {
		border-radius: 20px;
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		-ms-border-radius: 20px;
		-o-border-radius: 20px;
		box-shadow: 0px 0px 10px 0px #00000040;

	}
}

// estilos con barra lateral 
.wpbarra {
	width: 20%;
}

.wpcontent {
	width: 80%
}

.horizontalTxt {
	position: absolute;
	text-orientation: revert;
	writing-mode: horizontal-tb;
	transition: transform 0s ease-in-out;
	transform: rotate(0deg);
}

.verticalTxt {
	position: absolute;
	text-orientation: revert;
	writing-mode: tb;
	transform: rotate(180deg);
	transition: transform 0s ease-in-out;
}

.fadeIn {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s 0s, opacity 0s linear;
}

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

.rango_input {
	input[type="text"] {
		&::placeholder {
			color: red !important;
		}
	}
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="search"],
select {
	&:focus {
		outline: none;
		box-shadow: none;
	}
}

.btn:focus {
	outline: none;
	box-shadow: none;
}

.letterSpacingOne {
	letter-spacing: 1px;
}

.accordion {
	.accordion-item {
		margin-bottom: 2rem;

		.accordion-button {
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			text-align: center;
			display: block;
			width: 100%;
			color: #FFF;
			background: rgb(251, 119, 6);
			background: -moz-linear-gradient(90deg, rgba(251, 119, 6, 1) 0%, rgba(189, 59, 23, 1) 100%);
			background: -webkit-linear-gradient(90deg, rgba(251, 119, 6, 1) 0%, rgba(189, 59, 23, 1) 100%);
			background: linear-gradient(90deg, rgba(251, 119, 6, 1) 0%, rgba(189, 59, 23, 1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fb7706", endColorstr="#bd3b17", GradientType=1);
		}
	}
}

table {
	font-size: 0.75rem;
	color: #57585A;

	thead {
		tr {
			background-color: #D9D9D9;
			color: #F47921;
		}
	}

	th {
		&:first-child {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}

		&:last-child {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;

		}
	}

	th,
	td {
		padding: 0.5rem;
	}

	a {
		color: #57585A;
	}

	tbody {
		border: 1px solid #D9D9D9;

		tr {
			border-bottom: 1px solid #D9D9D9;

			td {
				border-right: 1px solid #D9D9D9;
			}
		}
	}
}

.nav-pills {
	.nav-link {
		font-size: 0.9rem;
		font-weight: normal;
		color: #57585A;
		background-color: transparent;
		text-align: flex-start;
		margin-bottom: 1rem;
		border-radius: 50px;
		-webkit-border-radius: 50px;
		-moz-border-radius: 50px;
		-ms-border-radius: 50px;
		-o-border-radius: 50px;

		&.active {
			background-color: #57585A;
			color: #FFF;
		}
	}
}

// .swiper {
// 	overflow-y: visible;
// }
// .swiper-slide {
// 	&:nth-child(2n) {
// 		position: relative;
// 		top: -40px;
// 	}
// }

.listProveedorBanner {
	ul {
		li {
			width: 6vw;

			picture {
				display: block;
			}

			span {
				font-weight: 400;
				font-family: 'Work Sans';
				line-height: 1;
				color: white;
			}
		}
	}
}

.listProveedor {
	ul {
		li {
			width: 10vw;

			picture {
				display: block;

				img {
					height: 4.5vw;
				}
			}

			span {
				font-size: 1.2vw;
				font-weight: 400;
				font-family: 'Work Sans';
				line-height: 1;
				color: white;
			}
		}
	}
}

.iconLittle {
	width: .8vw;
}

.iconMiddle {
	width: 1.2vw;
	height: 1.5vw;
}

.cursorPointer {
	cursor: pointer;
}

.focused {
	border: 1px solid $naranja !important;
}

.btnFocused {
	background: $naranja !important;
	color: white !important;
	border: 0;
	padding: .5vw;
}

.modal_filter {
	.modal-body {
		max-height: 30vw;
		overflow-y: auto;
	}

	.modal-body::-webkit-scrollbar {
		width: 0;
	}
}

.filtros_modal {
	.form-check-input:checked {
		background-color: $gris;
		border-color: $gris;
	}

	.form-check-input {
		border: 1px solid $gris;
	}
}

.galeria-videos {
	.swiper-wrapper {
		display: flex;
		justify-content: center;
	}
}

.certificacion-escritorio {
	.swiper-wrapper {
		display: flex;
		justify-content: center;
	}
}

.swiper-sostenibilidad {
	.swiper-slide {
		display: flex;
		justify-content: center;
	}
}

.paginador-swiper {
	.swiper-pagination-bullets {
		bottom: 0rem !important;
	}
}

.vh-slider {
	.swiper-wrapper {
		height: 100vh;
	}
}

.form-modalContactanos {
	.form-check-input:checked {
		background-color: #000;
		border-color: #000;
	}

	.form-check-input {
		border: 1px solid #000;
	}

	.form-check-input:focus {
		outline: none !important;
	}
}

.text-justify {
	text-align: justify;
}

.link-banner {
	text-decoration: none;
}

// estilos de formularios HubspotForm

.form_HubspotForm {

	.hs_firstname,
	.hs_lastname,
	.hs_tipo_de_documento,
	.hs_nro_de_documento,
	.hs_phone,
	.hs_email,
	.hs_perfil_de_linkedin {
		width: 100%;
		position: relative;
		display: inline-block;
		border: 1px solid $gris;
		padding: 0.5vw 1.5vw;
		line-height: 100%;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		margin-bottom: 5px;
		@extend .WorkSans;
		color: $gris;

		label {
			color: $gris !important;

		}

		.hs-error-msgs {
			margin-bottom: 0;
		}
	}

	.hs-form-booleancheckbox-display {
		line-height: 1.3;
	}

	.formProyecto {
		.hs_recaptcha {
			// height: 55px;
			max-height: 55px;
			iframe {
				max-height: 55px;
			}

			.grecaptcha-badge {
				max-height: 55px !important;
			}
		}

		.hs-form-booleancheckbox-display {
			line-height: 1.2;
		}

		.hs_firstname,
		.hs_lastname {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			label {
				width: 30%;
				font-size: 0.7vw;
			}

			.input {
				width: 70%;
			}

			.hs-error-msgs {
				width: 100%;
				margin-bottom: 0;

				.hs-error-msg {
					width: 100% !important;
				}
			}
		}

		input[type="text"] {
			text-transform: capitalize;
		}

		input[type="email"] {
			text-transform: none;
		}

		select {
			text-transform: math-auto;
		}

		.inputs-list {
			margin-bottom: 0.1rem;

			label {
				font-size: 0.8vw;
			}
		}

		.hs_tipo_de_documento,
		.hs_nro_de_documento,
		.hs_phone,
		.hs_email,
		.hs_perfil_de_linkedin {
			label {
				font-size: 0.7vw;
			}
		}

	}


	.hs_phone {
		.hs-input {
			display: flex;
		}
	}

	.no-list {
		list-style: none;
		padding-left: 0;

		.hs-error-msg {
			font-size: 0.7vw;
			color: red !important;

		}
	}

	.hs-button {
		background-color: $naranja;
		padding: 0.8vw 0;
		color: white;
		border: 0;
		width: 100%;
		position: relative;
		margin-top: 1vw;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
	}

	.hs-button:hover {
		background-color: $ladrillo-oscuro;
		@include toAnim(0.3s);
	}

	.inputs-list {
		list-style: none;
		padding-left: 0;

		input {
			position: relative;
			margin-right: 0.7vw;
		}

		span {
			text-align: justify;
			text-transform: math-auto;
		}
	}

	input[type="text"] {
		text-transform: capitalize !important;
	}

	input[type="email"] {
		text-transform: none !important;
	}

	select {
		text-transform: math-auto !important;
	}
}

.cambiarColor {
	//filter: invert(100%) saturate(0%);
}

// Estilo de los formularios de iframe
.form_is_iframe {
	min-height: 95vh;

}

.MaterialTitleStep_titleLbl__NIFuI {
	color: red !important;
}

.custom-iframe-chat {
	width: 100%;
	min-height: 60vh;
}

.custom-iframe {
	width: 100%;

}

.custom-iframe-garantia {
	min-height: 110vw;
}

.PillMapperElement_mobileContainer__8a-lv {
	background-color: transparent !important;
}

// estilos del  modal del pdf 

.modal-full {
	.modal-dialog {
		max-width: 100%;
		width: 100%;
		margin: 0;
	}
}

.custom-next-btn,
.custom-next-btn-a,
.custom-next-btn-b,
.custom-next-btn-c,
.custom-next-btn-y,
.custom-prev-btn,
.custom-prev-btn-a,
.custom-prev-btn-b,
.custom-prev-btn-c,
.custom-prev-btn-y {
	&:disabled {
		opacity: 0.5;
	}
}

.pf {
	font-size: 1vw !important;
}

.iframe-pagos {
	height: 70vh;
}

.slider-aliados {
	.swiper-wrapper {
		display: flex;
		align-items: center;
	}
}

.px-container {
	padding-left: 3.3rem;
	padding-right: 3.3rem;
}

#section_lanzamiento {
	height: 200vw;
}

.reactplayer-class {
	display: flex;
	justify-content: center;
}

.p-instagram {
	position: relative;
	padding-top: 6vw;
}

.pb6 {
	position: relative;
	padding-bottom: 6vw;
}

.text-uppercase {
	text-transform: uppercase;
}

.tooltip-inner {
	background-color: $naranja !important;
	color: white !important;
}


.multi-range-slider,
.sliderRAnge {
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 25px;
	background: white;
	border: 0 !important;
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s;
	-webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
	-moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;

	.caption {
		display: none !important;
	}

	.bar-inner {
		background-color: #F47921 !important;
		-webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
		-moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
		border: 0 !important;
	}

	.thumb {
		&::before {
			width: 27px !important;
			height: 27px !important;
			margin: -11px -12px !important;
			background-color: #F47921 !important;
			border: 5px solid white !important;
			-webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
			-moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
			box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
			filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
		}
	}

	.bar-left,
	.bar-right {
		background-color: #ffd2b2 !important;
		border-radius: 0 !important;
		-webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
		-moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
		padding: 4px 0px;
	}
}

.sticky-topgeneral {
	position: relative;
	//position: sticky;
	//top: 0;
	z-index: 1020
}

.custom-video-player {
	height: 22vw !important;
	//width: 50vw !important;
}

.check_config {
	.form-check-input {
		//width: 20px;
		//height: 20px;
		border-radius: 50%;
		background-color: white;
		border: 2px solid $naranja;
		appearance: none;
		-webkit-appearance: none;
		outline: none;
		cursor: pointer;
		position: relative;
	}

	.form-check-input:checked {
		background-color: $naranja;
		border: 2px solid $naranja;
	}

	.form-check-input:checked::before {
		content: '';
		display: block;
		//width: 12px;
		//height: 12px;
		border-radius: 50%;
		background-color: white;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

//big desktop
@media (min-width: 1920px) {
	.custom-iframe {
		max-width: 50%;

	}

	.custom-modalNotifica {
		.modal-content {
			width: 75rem;
		}
	}
}

@media (min-width: 1200px) {
	table {

		th,
		td {
			padding: 1rem;
		}
	}
}

@media (min-width: 992px) {
	.slider-obra {
		.swiper-wrapper {
			display: flex;
			justify-content: center;
		}
	}

	.contSmaller {
		max-width: 70%;
	}

}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {

	.containerfull {
		max-width: 90%;
	}

	.container {
		//max-width: 1140px;
	}

	.contSmall {
		max-width: 75%;
	}

	.contSmaller {
		max-width: 70%;
	}
}

@media only screen and (max-width: 1200px) {
	.lh1 {
		line-height: 1.4rem;
	}

	.iconLittle {
		width: .8rem;
	}

	.iconMiddle {
		width: 1.2rem;
		height: 1.5rem;
	}

	.modal_filter {
		.modal-body {
			max-height: 30rem;
			overflow-y: auto;
		}

	}

}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

	.custom-modalNotifica {
		.modal-content {
			width: 100%;
			height: 100%;
		}
	}

	.fz1 {
		font-size: 0.9rem !important;
	}

	.iframe-pagos {
		height: 40rem;
	}

	.form_HubspotForm {
		.formProyecto {

			.hs_firstname,
			.hs_lastname {
				label {
					font-size: 1rem;
				}
			}

			.inputs-list {
				margin-bottom: 0.1rem;

				label {
					font-size: 1rem;
				}

			}

			.hs_tipo_de_documento,
			.hs_nro_de_documento,
			.hs_phone,
			.hs_email,
			.hs_perfil_de_linkedin {
				label {
					font-size: 1rem;
				}
			}

		}
	}

	.custom-iframe {
		width: 100%;

	}

	.custom-iframe-chat {
		min-height: 50vh;
	}

	.modal-pagos {

		.modal-dialog {
			//width: 50%;
		}
	}

	.modalSmall {
		width: 40% !important;
		margin: auto;

		.modalheader {
			font-size: 1.6rem;
		}
	}

	.modal-descargaSimu {
		.modal-dialog {
			width: 50%;

		}
	}


	.wpbarra {
		width: 28%;
	}

	.wpcontent {
		width: 70%
	}

	.proveedores {
		background: red;
		min-height: 30vh;
		padding-bottom: 30vh;

		.banner {
			min-height: 30vh !important;
			display: flex;

			picture {
				display: block;

				img {
					height: 100%;
					object-fit: cover;
				}
			}
		}

		.wp {
			top: 50%;
			transform: translateY(-50%);

			h1 {
				font-size: 3.2rem;
			}

			h2 {
				font-size: 1.5rem;
			}
		}
	}

	// estilos de formularios HubspotForm

	.form_HubspotForm {

		.hs_firstname,
		.hs_lastname,
		.hs_tipo_de_documento,
		.hs_nro_de_documento,
		.hs_phone,
		.hs_email,
		.hs_perfil_de_linkedin {
			padding: 0.5rem 1.5rem;
			// margin-bottom: 1rem;

			.no-list {
				.hs-error-msg {
					font-size: 0.7rem;
				}
			}

			.hs-button {
				padding: 0.8rem 0;
				margin-top: 1rem;
			}

			.inputs-list {
				input {
					margin-right: 0.7rem;
				}
			}
		}

		.hs_firstname,
		.hs_lastname {
			label {
				width: 15%;
			}

			.input {
				width: 85%;
			}
		}

		.no-list {
			.hs-error-msg {
				font-size: 0.7rem;

			}
		}
	}

	.contSmaller {
		max-width: 80%;
	}


}

.d_block {
	display: block;
}

.h_auto_img {
	height: auto;
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
	.custom-modalNotifica {
		.modal-content {
			width: 100%;
			height: 100%;
		}
	}

	.fz1 {
		font-size: 0.9rem !important;
	}

	.form_HubspotForm {
		.formProyecto {

			.hs_firstname,
			.hs_lastname {
				label {
					width: 35% !important;
					font-size: 0.7rem;
				}

				.input {
					width: 65% !important;
				}
			}

			.inputs-list {
				margin-bottom: 0.1rem;

				label {
					font-size: 0.7rem;
				}

			}

			.hs_tipo_de_documento,
			.hs_nro_de_documento,
			.hs_phone,
			.hs_email,
			.hs_perfil_de_linkedin {
				label {
					font-size: 0.7rem;
				}
			}

		}
	}



	.contSmaller {
		max-width: 80%;
	}

	.modal-pagos {

		.modal-dialog {
			//width: 40%;
		}
	}

	.modalSmall {
		width: 30% !important;
		margin: auto;

		.modalheader {
			font-size: 1.6rem;
		}
	}

	.wpbarra {
		width: 25%;
	}

	.wpcontent {
		width: 75%
	}

	.listProveedorBanner {
		ul {
			li {
				width: 6rem;
			}
		}
	}

	.form_HubspotForm {

		.hs_firstname,
		.hs_lastname,
		.hs_tipo_de_documento,
		.hs_nro_de_documento,
		.hs_phone,
		.hs_email,
		.hs_perfil_de_linkedin {
			padding: 0.5rem 1.5rem;
			// margin-bottom: 1rem;

			.no-list {
				.hs-error-msg {
					font-size: 0.7rem;
				}
			}

			.hs-button {
				padding: 0.8rem 0;
				margin-top: 1rem;
			}

			.inputs-list {
				input {
					margin-right: 0.7rem;
				}
			}
		}

		.hs_firstname,
		.hs_lastname {
			label {
				width: 40%;
			}

			.input {
				width: 60%;
			}
		}

		.no-list {
			.hs-error-msg {
				font-size: 0.7rem;

			}
		}
	}
}

@media only screen and (min-width: 577px) and (max-width: 767px) {

	.fz1 {
		font-size: 0.9rem !important;
	}

	.custom-iframe {
		width: 60%;

	}

	.form_is_iframe {
		min-height: 150vh;
		position: relative;
		margin-bottom: 2.5rem;
	}

	.custom-iframe-chat {
		min-height: 100vh;
	}

	.contSmaller {
		max-width: 80%;
	}

	.modal-pagos {

		.modal-dialog {
			//width: 70%;
		}
	}

	.modal-descargaSimu {
		.modal-dialog {
			width: 70%;
		}
	}

	.modalSmall {
		width: 45% !important;
		margin: auto;

		.modalheader {
			font-size: 1.6rem;
		}
	}

	.wpbarra {
		width: 100%;
		padding: 1rem 0rem;
	}

	.wpcontent {
		width: 100%
	}

	.galeria-videos {
		.swiper-wrapper {
			justify-content: flex-start;
		}
	}





}

@media (max-width: 767px) {

	.custom-video-player {
		height: 8rem !important;
	}

	.custom-modalNotifica {
		.modal-content {
			width: 100%;
			height: 100%;
		}
	}

	.sticky-topgeneral {
		position: sticky;
		top: 0;
		z-index: 1020
	}

	.pb6 {
		padding-bottom: 6rem;
	}

	.px-container {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.contSmaller {
		max-width: 95%;
	}

	.slider-obramobile {
		.swiper-wrapper {
			display: flex;
			justify-content: center;
		}
	}

	.form_HubspotForm {

		.hs_firstname,
		.hs_lastname,
		.hs_tipo_de_documento,
		.hs_nro_de_documento,
		.hs_phone,
		.hs_email,
		.hs_perfil_de_linkedin {
			padding: 0.5rem 1.5rem;
			margin-bottom: 1rem;

			.no-list {
				.hs-error-msg {
					font-size: 0.7rem !important;
				}
			}

			.hs-button {
				padding: 0.8rem 0;
				margin-top: 1.5rem !important;
			}

			.inputs-list {
				input {
					margin-right: 0.7rem;
				}
			}
		}

		.no-list {
			.hs-error-msg {
				font-size: 0.7rem;

			}
		}
	}

	.form_HubspotForm {
		.formProyecto {

			.hs_firstname,
			.hs_lastname {
				label {
					font-size: 0.8rem;
				}
			}

			.inputs-list {
				margin-bottom: 0.1rem;

				label {
					font-size: 0.8rem;
				}

			}

			.hs_tipo_de_documento,
			.hs_nro_de_documento,
			.hs_phone,
			.hs_email,
			.hs_perfil_de_linkedin {
				label {
					font-size: 0.8rem;
				}
			}

		}
	}
}

//only phone
@media (max-width: 576px) {

	.custom-video-player {
		height: 10rem !important;

	}

	.fz1 {
		font-size: 0.9rem !important;
	}

	.custom-iframe {
		width: 100%;

	}

	.modal-descargaSimu {
		.modal-dialog {
			width: 90%;
		}
	}

	.certificacion {
		.swiper-wrapper {
			display: flex !important;
			justify-content: center !important;
		}
	}

	.certificacion-escritorio {
		.swiper-wrapper {
			justify-content: flex-start;
		}
	}

	.galeria-videos {
		.swiper-wrapper {
			justify-content: flex-start;
		}
	}

	.modal-pagos {

		.modal-dialog {
			//width: 100%;
		}
	}

	main {
		padding-top: 8rem;
	}

	.slider_fecha {
		height: 5rem;

		.swiper-wrapper {
			height: 5rem;
		}
	}



	.modalSmall {
		width: 80% !important;
		margin: auto !important;

		.modalheader {
			font-size: 1.6rem;
		}
	}

	.wpbarra {
		width: 100%;
		padding: 1rem 0rem;
	}

	.wpcontent {
		width: 100%
	}

	// .horizontalTxt {
	//     position: absolute;
	//     text-orientation: revert;
	//     writing-mode: horizontal-tb;
	// }

	.verticalTxt {
		writing-mode: horizontal-tb;
		transform: rotate(0deg);
	}



}

@media (max-width:992px) {
	.listProveedor {
		ul {
			li {
				width: 8rem;

				picture {
					img {
						height: 4.5rem;
					}
				}

				span {
					font-size: 0.8rem;
				}
			}
		}
	}

	.listProveedorBanner {
		ul {
			li {
				width: 6rem;
			}
		}
	}
}